import ApiService from "@/core/services/api.service";

export default {

    // -----API DATA AFKIR DINI------
    getHistoriList(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/data/afkir-dini", { params: credentials })
    },
    getHistoriDetail(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/data/afkir-dini/single", { params: credentials })
    },
    getTarget(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/data/afkir-dini/target", { params: credentials })
    },
    getTargetDetail(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/data/afkir-dini/target/single", { params: credentials })
    },

    getAfkirDiniSE() {
        ApiService.setHeader();
        return ApiService.get("v2/data/afkir-dini/se")
    },
    getAfkirDiniEdit(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/data/afkir-dini/edit", { params: credentials })
    },

    addAfkirDini(credentials) {
        ApiService.setHeader();
        return ApiService.post("v2/data/afkir-dini", credentials)
    },
    updateAfkirDini(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/data/afkir-dini", credentials)
    },
    deleteAfkirDini(credentials) {
        ApiService.setHeader();
        return ApiService.delete("v2/data/afkir-dini", { data: credentials })
    },
}